<template>
  <div id="video">
    <div id="testVideo"></div>
    <!--<canvas id="canvas" style="width: 100%;height:200px;position:relative;z-index: 9999999999999999999;background-color: #fff;"></canvas>-->
    <!--:src="videoUrl" controls-->
    <!--style="width: 100%;position:relative;z-index: 9999999999999999999;background-color: #fff;"-->
    <!--<video id="videoT" preload="auto" style="width: 100%;height:200px;display: none;" playsinline webkit-playsinline x5-playsinline ></video>-->
    <div class="btn" @click="videoChange">切换下一个视频</div>
    <div class="loading" v-if="isLoading">
      <van-loading color="#fff" />
    </div>
  </div>
</template>

<script>
import WebVRPolyfill from 'webvr-polyfill';
import * as THREE from 'three';
import Hls from 'hls.js';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';
// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
// import { SSAARenderPass } from 'three/examples/jsm/postprocessing/SSAARenderPass.js';
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
// import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js';
// import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
// import TcAdapter from 'tcadapter';
import { VR, AVR } from '@/assets/js/vr/mxreality';
import { mapGetters } from 'vuex';
// AVR.playerIcon.playSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" version="1.1" width="32" height="32" ><path fill="#ffffff" d="M9.789,2.827a2,2,0,0,1,3.422,0l7.952,13.138A2,2,0,0,1,19.452,19H3.548a2,2,0,0,1-1.711-3.036Z" transform="translate(27,4.5) rotate(90)"/></svg>';
AVR.playerIcon.gyroSvg = '<svg xmlns="http://www.w3.org/2000/svg" style="width:22px;height:16px;font-weight: bold" aria-hidden="true"><use  xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_hand_01"/></svg>';
AVR.playerIcon.vrSvg = '<svg xmlns="http://www.w3.org/2000/svg" style="width:24px;height:14px;font-weight: bold" aria-hidden="true"><use  xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_vr_01"/></svg>';
AVR.playerIcon.exit = require('@/assets/images/icon_close_02.png');
window.polyfill = new WebVRPolyfill();
window.THREE = THREE;
// window.TcAdapter = TcAdapter;
window.Hls = Hls;
// window.EffectComposer = EffectComposer;
// window.RenderPass = RenderPass;
// window.SSAARenderPass = SSAARenderPass;
export default {
  name: 'TestVideo',
  components: {},
  data () {
    return {
      urls: [{
        url: './static/images/img_thumb_02.jpg',
        name: 'BGS限定  「東京ガールズコレクション」VR映像',
        type: 1,
        id: 5,
        isHemisphere: true,
        videoUrl: './static/video/sintel.mp4'
      }, {
        url: './static/images/img_thumb_02.jpg',
        name: 'BGS限定  「東京ガールズコレクション」VR映像',
        type: 1,
        id: 5,
        isHemisphere: true,
        videoUrl: './static/video/sintel.ogv'
      }, {
        url: './static/images/img_thumb_02.jpg',
        name: 'BGS限定  「東京ガールズコレクション」VR映像',
        type: 1,
        id: 5,
        isHemisphere: true,
        videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/video2/2022-02-15-16:19_9826_4k.mp4'
      }, {
        url: './static/images/img_thumb_02.jpg',
        name: 'BGS限定  「東京ガールズコレクション」VR映像',
        type: 1,
        id: 5,
        isHemisphere: true,
        videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/TGC~1920i.mp4'
      }, {
        url: './static/images/img_thumb_14.jpg',
        name: '人気コスプレイヤー  「五木あきら」撮影風景',
        type: 1,
        id: 3,
        isHemisphere: true,
        videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/itsuki%20akira_posing~1920i.mp4'
      }, {
        url: './static/images/img_thumb_15.jpg',
        name: 'BGS限定  「デヴィ夫人コーポレートアンバサダー」記念コンテンツ',
        type: 1,
        id: 4,
        isHemisphere: true,
        videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/msdeviVR0204~1920i.mp4'
      }, {
        url: './static/images/img_thumb_16.jpg',
        name: '美女モデルでコスプレイヤー「深海永理」',
        type: 2,
        id: 1,
        isHemisphere: true,
        videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/shinkai%20eri%20main%20ver1~1920i.mp4'
      }, {
        url: './static/images/img_thumb_17.jpg',
        name: '10（ヒトマル）式戦車  VR',
        type: 2,
        id: 2,
        videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/tank~1920i.mp4'
      }, {
        url: './static/images/img_thumb_18.jpg',
        name: '天才コスプレイヤー「東堂とも」',
        type: 1,
        id: 6,
        isHemisphere: true,
        videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/todo%20tomo_main%20ver1~1920i.mp4'
      }],
      threeInfo: {
        renderer: null, // 渲染器
        scene: null, // 场景
        container: null // 容器
      },
      videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/videoDirectory/2022-02-22-16:18:18/5341_720p.m3u8',
      currentIndex: 1,
      vr: null,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(['htmlWidth'])
  },
  created () {
  },
  mounted () {
    alert(window.navigator.userAgent);
    /* eslint-disable */
    // const player = TCPlayer(document.getElementById('videoT'), {});
    // player.src('https://video-private-1259789488.cos.ap-guangzhou.myqcloud.com/hls/example.m3u8?ci-process=pm3u8&expires=3600&q-sign-algorithm=sha1&q-ak=AKIDWszqQkZfFakeIhRkZ9TaZIkvYx5xZxAV&q-sign-time=1636615550;1936619150&q-key-time=1636615550;1936619150&q-header-list=&q-url-param-list=&q-signature=abf662b33ec5e960c4d35724b849cec74e580292');
    // player.src(this.videoUrl);
    // player.on('error',(e)=>{
    //   console.log(e)
    // });
    // player.src('https://vravo-1258661974.cos.accelerate.myqcloud.com/TGC~1920i.mp4')
    // console.log(player);
    /* eslint-enable */
    this.init();
    this.onResize();
  },
  watch: {

    /* 监听窗口大小变化 */
    htmlWidth () {
      this.onResize();
    }
  },
  methods: {
    videoChange () {
      const { currentIndex, urls } = this;
      const item = urls[currentIndex];
      // this.videoUrl = item.videoUrl;
      // this.videoUrl = 'https://vravo-1258661974.cos.ap-tokyo.myqcloud.com/2022-02-15-16_19_9826_4k.m3u8';
      this.setVideo(this.videoUrl, { isHemisphere: item.isHemisphere || false });
      this.currentIndex = currentIndex < urls.length - 1 ? (currentIndex + 1) : 0;
    },
    async init () {
      const { vr } = this;
      vr && vr.destroy();
      let renderer;
      AVR.debug = true;
      if (!AVR.Broswer.isIE() && AVR.Broswer.webglAvailable()) {
        // { antialias: true, alpha: true }

        renderer = new THREE.WebGLRenderer({
          antialias: true,
          alpha: true
        });
        renderer.xr.enabled = true;
        renderer.xr.setReferenceSpaceType('local');
      } else {
        return alert('ご利用のブラウザは360度動画の再生に対応しておりません。');
      }
      const container = document.getElementById('testVideo');
      const scene = new THREE.Scene();
      this.threeInfo = {
        renderer,
        scene,
        container
      };
      // fov 选项可调整初始视频远近
      const nowVR = new VR(scene, renderer, container, { fov: 90, far: 2000 });
      nowVR.operationCallback = this.operationCallback;
      nowVR.loadProgressManager.onLoad = function () {
        // 视频静音
        // nowVR.video.muted = true;
        nowVR.bindVolumeBar();
      };
      // waiting
      nowVR.onLoadingChange = (status) => {
        console.log(!nowVR.video.paused, status);
        this.onLoadingChange(!nowVR.video.paused && status);
      };
      // 光线
      const light = new THREE.DirectionalLight(0xffffff);
      scene.add(light);

      /* -------------------控制器显示--------------------*/
      let controller = null;
      try {
        const { buildController } = this;
        controller = renderer.xr.getController(0);
        controller.addEventListener('connected', function (event) {
          this.add(buildController(event.data));
        });
        controller.addEventListener('disconnected', function () {
          this.remove(this.children[0]);
        });
        scene.add(controller);
        const controllerModelFactory = new XRControllerModelFactory();
        const controllerGrip = renderer.xr.getControllerGrip(0);
        controllerGrip.add(controllerModelFactory.createControllerModel(controllerGrip));
      } catch (e) {
        controller = null;
      };

      /**
       * 光线投射用于进行鼠标拾取（在三维空间中计算出鼠标移过了什么物体）
       * Raycaster( origin : Vector3, direction : Vector3, near : Float, far : Float )
       * origin —— 光线投射的原点向量。
       * direction —— 向射线提供方向的方向向量，应当被标准化。
       * near —— 返回的所有结果比near远。near不能为负值，其默认值为0。
       * far —— 返回的所有结果都比far近。far不能小于near，其默认值为Infinity（正无穷。）
       *
       */
      const raycaster = new THREE.Raycaster();
      const tempMatrix = new THREE.Matrix4();
      const clock = new THREE.Clock();
      let direction = {};
      let clockAdd = 0;
      // const ssaaParams = {
      //   sampleLevel: 2,
      //   renderToScreen: true,
      //   unbiased: true
      // };
      // const composer = new EffectComposer(renderer);
      // const renderPass = new RenderPass(scene, nowVR.camera);
      // composer.addPass(renderPass);
      // const ssaaRenderPass = new SSAARenderPass(scene, nowVR.camera);
      // composer.addPass(ssaaRenderPass);
      // const copyPass = new ShaderPass(CopyShader);
      // composer.addPass(copyPass);
      // ssaaRenderPass.sampleLevel = ssaaParams.sampleLevel;
      // ssaaRenderPass.unbiased = ssaaParams.unbiased;
      // ssaaRenderPass.enabled = true;
      // copyPass.enabled = false;
      let oldVisible = null;
      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);
      await nowVR.init(() => {
        if (controller) {
          const visible = nowVR.xrBtnGroup && nowVR.xrBtnGroup.visible || false;
          if (controller.children.length && oldVisible !== visible) {
            oldVisible = visible;
            controller.children.forEach((item) => {
              item.visible = visible;
            });
          }
          // const width = container.offsetWidth;
          // const height = container.offsetHeight;
          // composer.setPixelRatio(renderer.getPixelRatio());
          // composer.setSize(width, height);
          // composer.render(scene, nowVR.camera);
          if (nowVR.XR && visible && clockAdd > 0.3) {
            tempMatrix.identity().extractRotation(controller.matrixWorld);
            raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
            raycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);
            const { x, y, z } = raycaster.ray.direction;
            if (!(direction.x === x && direction.y === y && direction.z === z)) {
              direction = {
                x,
                y,
                z
              };
              const intersects = raycaster.intersectObject(nowVR.xrBtnGroup, true);
              if (intersects.length > 0) {

                /** intersect
                 * distance —— 射线投射原点和相交部分之间的距离。
                 * point —— 相交部分的点（世界坐标）
                 * face —— 相交的面
                 * faceIndex —— 相交的面的索引
                 * object —— 相交的物体
                 * uv —— 相交部分的点的UV坐标。
                 * uv2 —— 交点处的第二组UV坐标
                 * instanceId – 光线与InstancedMesh相交的实例的索引号
                 * */
                const res = intersects.filter(function (res) {
                  return res && res.object && res.object.visible && !res.object.isNoIntersect;
                })[0];
                const currentObject = res.object;
                if (nowVR.selectedXRBtn !== currentObject) {
                  if (nowVR.selectedXRBtn) {
                    nowVR.selectedXRBtn.material.opacity = nowVR.selectedXRBtn.currentOpacity;
                    // nowVR.selectedXRBtn.material.color.setHex(nowVR.selectedXRBtn.currentHex);
                  }
                  if (currentObject.name) {
                    nowVR.selectedXRBtn = currentObject;
                    nowVR.selectedXRBtn.currentPoint = res.point; // 相交部分的点（世界坐标）
                    nowVR.selectedXRBtn.currentOpacity = nowVR.selectedXRBtn.material.opacity;
                    nowVR.selectedXRBtn.material.opacity = 1;
                  } else {
                    nowVR.selectedXRBtn = null;
                  }
                  // nowVR.selectedXRBtn.currentHex = nowVR.selectedXRBtn.material.color.getHex();
                  // nowVR.selectedXRBtn.material.color.setHex(0xff0000);
                }
              } else if (nowVR.selectedXRBtn) {
                // nowVR.selectedXRBtn.material.color.setHex(nowVR.selectedXRBtn.currentHex);
                nowVR.selectedXRBtn.material.opacity = nowVR.selectedXRBtn.currentOpacity;
                nowVR.selectedXRBtn = null;
              }
            }
            clockAdd = 0;
          } else if (clockAdd > 100) {
            clockAdd = 0;
          }
          clockAdd += clock.getDelta();
        }
      }); // 初始化vr
      this.vr = nowVR;

      /* -------------------控制器显示 end--------------------*/
      this.videoChange();
      // this.setVideo('https://vravo-1258661974.cos.accelerate.myqcloud.com/tank~1920i.mp4');
      // this.setVideo('https://vravo-1258661974.cos.accelerate.myqcloud.com/itsuki%20akira_posing~1920i.mp4');
      // setTimeout(() => {
      //   this.setVideo('https://vravo-1258661974.cos.accelerate.myqcloud.com/itsuki%20akira_posing~1920i.mp4');
      // }, 30000);
    },
    setVideo (url, options) {
      const { vr } = this;
      vr.play(url, vr.resType.sliceVideo, options || {}); // 初始化视频
      // vr.video.setAttribute('loop', 'loop');
      // vr.video.crossOrigin = 'Anonymous';
      vr.video.onended = this.onended;// 视频结束
    },
    // 沉浸式控制器射线添加
    buildController (data) {

      let geometry, material;

      switch (data.targetRayMode) {

        case 'tracked-pointer':

          geometry = new THREE.BufferGeometry();
          geometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0, 0, 0, -1], 3));
          geometry.setAttribute('color', new THREE.Float32BufferAttribute([0.5, 0.5, 0.5, 0, 0, 0], 3));

          material = new THREE.LineBasicMaterial({ vertexColors: true, blending: THREE.AdditiveBlending });

          return new THREE.Line(geometry, material);

        case 'gaze':

          geometry = new THREE.RingGeometry(0.02, 0.04, 32).translate(0, 0, -1);
          material = new THREE.MeshBasicMaterial({ opacity: 0.5, transparent: true });
          return new THREE.Mesh(geometry, material);

      }

    },
    operationCallback (el, name, isAtive) {
      console.log(el, name, isAtive);
    },
    onLoadingChange (status) {
      this.isLoading = status;
    },
    onended () {
    },
    onResize () {
    }
  }
};
</script>

<style>
  canvas {
    background-color: #07c160;
  }
</style>
<style scoped lang="less">
  #video {
    position: relative;
    .loading{
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 999999;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
    &, #testVideo {
      width: 100%;
      height: 100%;
    }
    color: #1B1C1D;
    .btn {
      font-size: 28px;
      color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 999;
    }
  }
</style>
